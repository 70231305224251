body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  color: 'black'
}

.sg-terminal {
  max-height: 600px;
  height: 600px;
}

.MuiDataGrid-row:nth-child(odd) {
  background-color: #00000006;
}

/* ::-webkit-scrollbar { */
/*   width: 6px; */
/* } */
/*  */
/* ::-webkit-scrollbar-track { */
/*   display: none; */
/* } */
/*  */
/* ::-webkit-scrollbar-thumb { */
/*   background: #888; */
/*   border-radius: 100px; */
/* } */
/*  */
/* ::-webkit-scrollbar-thumb:hover { */
/*   background: #888; */
/*   border-radius: 100px; */
/* } */
/*  */
/* .scrollable { */
/*   overflow: hidden; */
/* } */
/*  */
/* .scrollable:active, .scrollable:hover, .scrollable:focus { */
/*   overflow: auto; */
/* } */
